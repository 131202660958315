import storeRequest from './request/storeRequest';
export default {
  getPixels() {
    const url = '/users/share-pixel/list-pixels';
    return storeRequest.get(url);
  },
  getHistory(query) {
    const url = `/users/share-pixel/history?page=${query?.page}&limit=${query?.limit}`;
    return storeRequest.get(url);
  },
  sharePixel(data) {
    const url = '/users/share-pixel/share';
    return storeRequest.post(url, data);
  },
};
